export const roundToNearestNumber = (input, number) => {
    return Math.round(input / number) * number;
}

export const radianToDegree = rad => {
    return rad * (180.0 / Math.PI);
}

export const secondToMilisecond = sec => {
    return Math.round(1000 * sec);
}