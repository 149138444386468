export const convertToFilename = (sentence) => {
    // Xóa dấu cách và thay thế bằng '\ '
    // const filename = sentence.replace(/\s/g, '\\ ');
    const filename = sentence


    // Thêm đuôi file '.mp3'
    return 'smart_voice/' + filename + '.mp3';
}

export const convertToEngFilename = (sentence) => {
    const filename = sentence

    return 'eng_voice_yoga_v2/' + filename + '.mp3';
}

export const convertToJpFilename = (sentence) => {
    const filename = sentence

    return 'japan_voice/' + filename + '.mp3';
}