import { isMobile } from "react-device-detect";

export const CONFIG = {
    ENABLE_BODY_CORRECTION: false
}

export const MOVENET_CONFIGS = {
    maxPoses: 1,
    type: 'lightning',
    scoreThreshold: 0.2
};

export const LINE_WIDTH = 5;
export const DEFAULT_RADIUS = 6;

export const VIDEO_CONFIGS = {
    facingMode: "user",
    deviceId: "",
    frameRate: { max: 60, ideal: 30 },
    width: isMobile ? 360 : 640,
    height: isMobile ? 270 : 480
};

export const EXERCISE_DIFFICULTY = {
    EASY: 'easy',
    MEDIUM: 'medium',
    HARD: 'hard'
}

export const ARRAY_BODY = {
    LEFT_HAND: [5, 7, 9],
    RIGHT_HAND: [6, 8, 10],
    LEFT_SHOULDER: [7, 5, 11],
    RIGHT_SHOULDER: [8, 6, 12],
    LEFT_HIP: [5, 11, 13],
    RIGHT_HIP: [6, 12, 14],
    LEFT_LEG: [11, 13, 15],
    RIGHT_LEG: [12, 14, 16],
    NECK: [6, 0, 5],
}