import React, { useEffect, useRef, useState } from "react";
import GaugeComponent from "react-gauge-component";
import { throttle } from "lodash";

interface ScoreChartProps {
  score: number;
}

/**
 * chart render điểm
 */
export const ScoreChart = React.memo(({ score }: ScoreChartProps) => {
  const countupRef = useRef<HTMLDivElement>(null);
  let countUpAnim;
  const [countUpValue, setCountUpValue] = useState<number>(0);
  const [throttledUpdateScore] = useState(() =>
    throttle((scorePractice: number) => {
      if (countUpAnim) {
        countUpAnim.update(scorePractice);
      }
      setCountUpValue(scorePractice);
    }, 1000)
  );

  useEffect(() => {
    throttledUpdateScore(score);
  }, [score]);

  useEffect(() => {
    initCountUp();
  }, []);

  useEffect(() => {
    if (countUpValue !== null && countUpAnim) {
      countUpAnim.update(countUpValue);
    }
  }, [countUpValue]);

  async function initCountUp() {
    const countUpModule = await import("countup.js");
    countUpAnim = new countUpModule.CountUp(countupRef.current, score, {
      startVal: countUpAnim ? countUpAnim?.frame : 0,
      duration: 1,
    });

    if (!countUpAnim.error) {
      countUpAnim.start();
      setCountUpValue(score);
    } else {
      console.error(countUpAnim.error);
    }
  }
  return (
    <div className="score-chart">
      <GaugeComponent
        pointer={{
          type: "blob",
          width: 30,
        }}
        labels={{
          valueLabel: {
            hide: true,
          },
        }}
        arc={{
          subArcs: [
            {
              limit: 20,
              color: "#EA4228",
              showTick: false,
            },
            {
              limit: 40,
              color: "#F58B19",
              showTick: false,
            },
            {
              limit: 60,
              color: "#F5CD19",
              showTick: false,
            },
            {
              limit: 100,
              color: "#5BE12C",
              showTick: false,
            },
          ],
        }}
        value={
          countUpValue !== null ? Math.round(countUpValue) : Math.round(score)
        }
      />
      <div className="count-up-container text-white font-bold text-[40px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <span ref={countupRef}></span>%
      </div>
    </div>
  );
});
