//Dont' remove
import "@tensorflow/tfjs-backend-webgl";
import "@tensorflow/tfjs-converter";

import * as tf from "@tensorflow/tfjs-core";

//

import * as poseDetection from "@tensorflow-models/pose-detection";
import { keypointApi } from "./utils/request";
import { roundToNearestNumber, secondToMilisecond } from "./utils/number";
import { sleep } from "./utils/time";
import { getCurrentDateTime } from "../../utils/time";
import {
  convertToFilename,
  convertToEngFilename,
  convertToJpFilename,
} from "./utils/text";
import { PixelInput } from "@tensorflow-models/pose-detection/dist/shared/calculators/interfaces/common_interfaces";
import { mapEngPath, mapJpPath } from "./utils/mapping_voice";
import { json } from "stream/consumers";
import axios from "axios";

const fullBody = [
  "LEFT_HAND",
  "RIGHT_HAND",
  "LEFT_SHOULDER",
  "RIGHT_SHOULDER",
  "LEFT_HIP",
  "RIGHT_HIP",
  "LEFT_LEG",
  "RIGHT_LEG",
];

const ARRAY_BODY = {
  LEFT_HAND: [5, 7, 9],
  RIGHT_HAND: [6, 8, 10],
  LEFT_SHOULDER: [7, 5, 11],
  RIGHT_SHOULDER: [8, 6, 12],
  LEFT_HIP: [5, 11, 13],
  RIGHT_HIP: [6, 12, 14],
  LEFT_LEG: [11, 13, 15],
  RIGHT_LEG: [12, 14, 16],
  NECK: [6, 0, 5],
};

const VECTOR_BODY = {
  LEFT_HAND: [7, 9],
  RIGHT_HAND: [8, 10],
  LEFT_SHOULDER: [7, 5],
  RIGHT_SHOULDER: [8, 6],
  LEFT_HIP: [5, 11],
  RIGHT_HIP: [6, 12],
  LEFT_LEG: [11, 13, 15],
  RIGHT_LEG: [12, 14, 16],
};

const DEFAULT_VOICE = {
  LEFT_HAND: "/audio/dong_tac_tay_trai_chua_dung_190923113928.wav",
  RIGHT_HAND: "/audio/dong_tac_tay_phai_chua_dung_190923105243.wav",
  LEFT_SHOULDER: "/audio/dong_tac_vai_trai_chua_dung_190923114058.wav",
  RIGHT_SHOULDER: "/audio/dong_tac_vai_phai_chua_dung_190923114114.wav",
  LEFT_HIP: "/audio/dong_tac_hong_trai_chua_dung_190923114040.wav",
  RIGHT_HIP: "/audio/dong_tac_hong_phai_chua_dung_190923114024.wav",
  LEFT_LEG: "/audio/dong_tac_chan_trai_chua_dung_190923113944.wav",
  RIGHT_LEG: "/audio/dong_tac_chan_phai_chua_dung_190923113958.wav",
};

const ENG_DEFAULT_VOICE = {
  LEFT_HAND: "eng_voice_yoga_v2/Left hand movement is incorrect.mp3",
  RIGHT_HAND: "eng_voice_yoga_v2/Right hand movement is incorrect.mp3",
  LEFT_SHOULDER: "eng_voice_yoga_v2/Left shoulder movement is incorrect.mp3",
  RIGHT_SHOULDER: "eng_voice_yoga_v2/Right shoulder movement is incorrect.mp3",
  LEFT_HIP: "eng_voice_yoga_v2/Left hip movement is incorrect.mp3",
  RIGHT_HIP: "eng_voice_yoga_v2/Right hip movement is incorrect.mp3",
  LEFT_LEG: "eng_voice_yoga_v2/Left leg movement is incorrect.mp3",
  RIGHT_LEG: "eng_voice_yoga_v2/Right leg movement is incorrect.mp3",
};

const JP_DEFAULT_VOICE = {
  LEFT_HAND: "japan_voice/Left hand movement is incorrect.mp3",
  RIGHT_HAND: "japan_voice/Right hand movement is incorrect.mp3",
  LEFT_SHOULDER: "japan_voice/Left shoulder movement is incorrect.mp3",
  RIGHT_SHOULDER: "japan_voice/Right shoulder movement is incorrect.mp3",
  LEFT_HIP: "japan_voice/Left hip movement is incorrect.mp3",
  RIGHT_HIP: "japan_voice/Right hip movement is incorrect.mp3",
  LEFT_LEG: "japan_voice/Left leg movement is incorrect.mp3",
  RIGHT_LEG: "japan_voice/Right leg movement is incorrect.mp3",
};

const bboxSample = {
  x1: 0.2,
  y1: 0.2,
  x2: 0.8,
  y2: 0.8,
};

export interface Pose {
  score: number;
  keypoints: { x: number; y: number }[];
}

interface CustomPoseInitParam {
  deviceId?: string;
  videoUrl: string;
  keyPointUrl: string;
}

const correctPositionThreshold = 0.1;

class CustomPoseDetection {
  isInitPose = false;
  private poseUtil: PoseUtil | null = null;
  private videoUtil: VideoUtil | null = null;
  private logUtil: LogUtil | null = null;
  timer = 0;

  constructor() {
    this.timer = 0;
  }

  async initPoseUtil() {
    this.poseUtil = new PoseUtil();
    await this.poseUtil.init();
    this.isInitPose = true;
  }

  async init({ videoUrl, deviceId, keyPointUrl }: CustomPoseInitParam) {
    this.videoUtil = new VideoUtil();
    this.logUtil = new LogUtil();

    await this.videoUtil.init({ videoUrl, keyPointUrl });
    this.logUtil.init({ deviceId, videoUrl });
  }

  async setNextVideo(videoUrl, keyPointUrl) {
    // lấy keypoint của video kế tiếp
    await this.videoUtil.setVideoUrl(videoUrl, keyPointUrl);
  }

  async detect(webcamElement: PixelInput, videoElement, lang) {
    //truyền tham số video, webcam element, language
    //detect
    const webcamPoses =
      (await this.poseUtil.currentKeypoint(webcamElement)) ?? [];
    //Get current keypoint of video (not webcam)
    const keypoint = await this.videoUtil.getKeypointMetaData(videoElement);
    const videoPoses =
      this.videoUtil.currentKeypoint(videoElement, keypoint) ?? [];
    const videoAudios = this.videoUtil.currentAudio(keypoint) ?? [];
    const hasData = webcamPoses.length && videoPoses.length;
    const isStop = this.videoUtil.currentState(keypoint);
    const isCorrectPratice = false;
    const scoreCompare = !(hasData && isStop)
      ? null
      : this.poseUtil.comparePose(
          webcamPoses[0]["keypoints"],
          videoPoses[0]["keypoints"],
          { focusedPoint: this.videoUtil.getFocusedPoint() }
        );
    const score = !scoreCompare
      ? null
      : this.getPoseUtil().getPoseError(scoreCompare);
    const audioTag = !score
      ? null
      : this.poseUtil.getAudio(score, videoAudios, lang);
    const scorePractice = !scoreCompare
      ? 0
      : this.poseUtil.getScorePractice(scoreCompare);
    return {
      videoPoses: videoPoses,
      webcamPoses: webcamPoses,
      scoreCompare: scoreCompare,
      audioTag,
      isStop,
      isCorrectPratice,
      scorePractice,
    };
  }

  getPoseUtil() {
    return this.poseUtil;
  }

  getLogUtil() {
    return this.logUtil;
  }

  getVideoUtil(){
    return this.videoUtil;
  }

  dispose() {
    this.poseUtil.dispose();
  }
}

class PoseUtil {
  //xử lý, tính toán keypoint
  videoPoses: Pose[] | null = null;
  webcamPoses: Pose[] | null = null;
  model: poseDetection.SupportedModels = null;
  detector: poseDetection.PoseDetector = null;

  constructor() {
    this.videoPoses = null; //keypoint người mẫu
    this.webcamPoses = null; // keypoint người tập

    this.model = null;
    this.detector = null; //bộ cài đặt

    //this.init();
  }

  async init() {
    await tf.ready();
    this.model = poseDetection.SupportedModels.MoveNet;
    const modelType = poseDetection.movenet.modelType.SINGLEPOSE_LIGHTNING;

    console.log("[PoseUtil init]", getCurrentDateTime());
    this.detector = await poseDetection.createDetector(this.model, {
      modelType: modelType,
    }); //khởi tạo model detect keypoint
    console.log("[PoseUtil end init]", getCurrentDateTime());
  }

  //get keypoint của webcam bằng AI
  async currentKeypoint(webcam: PixelInput) {
    if (this.detector)
      return await this.detector.estimatePoses(webcam, {
        maxPoses: 1, //When maxPoses = 1, a single pose is detected
        flipHorizontal: false,
      });
    return null;
  }

  comparePose(webcamPoses, videoPoses, { focusedPoint }) {
    const body = focusedPoint ?? fullBody;
    let res = [];
    for (const partOfPose of body) {
      // let array = ARRAY_BODY[partOfPose];
      let array = VECTOR_BODY[partOfPose];
      // let compare = this.comparePartOfPose(webcamPoses, videoPoses, array);
      let compare = this.compareVectorPose(webcamPoses, videoPoses, array);
      res.push({
        label: partOfPose,
        array: array,
        ...compare,
      });
    }
    return res;
  }

  comparePartOfPose(webcamPoses, videoPoses, array) {
    let v1 = [
      webcamPoses[array[1]].x - webcamPoses[array[0]].x,
      webcamPoses[array[1]].y - webcamPoses[array[0]].y,
    ];
    let v2 = [
      webcamPoses[array[1]].x - webcamPoses[array[2]].x,
      webcamPoses[array[1]].y - webcamPoses[array[2]].y,
    ];

    let alphaWebcam = Math.acos(
      (v1[0] * v2[0] + v1[1] * v2[1]) /
        (Math.sqrt(v1[0] * v1[0] + v1[1] * v1[1]) *
          Math.sqrt(v2[0] * v2[0] + v2[1] * v2[1]) +
          0.0000001)
    );

    v1 = [
      videoPoses[array[1]].x - videoPoses[array[0]].x,
      videoPoses[array[1]].y - videoPoses[array[0]].y,
    ];
    v2 = [
      videoPoses[array[1]].x - videoPoses[array[2]].x,
      videoPoses[array[1]].y - videoPoses[array[2]].y,
    ];

    let alphaVideo = Math.acos(
      (v1[0] * v2[0] + v1[1] * v2[1]) /
        (Math.sqrt(v1[0] * v1[0] + v1[1] * v1[1]) *
          Math.sqrt(v2[0] * v2[0] + v2[1] * v2[1]) +
          0.0000001)
    );

    return {
      value:
        Math.abs(alphaVideo - alphaWebcam) >
        Math.min(0.5 * alphaWebcam, Math.PI / 10),
      alphaWebcam: alphaWebcam,
      alphaVideo: alphaVideo,
      valueCheck:
        Math.abs(alphaVideo - alphaWebcam) /
        Math.min(0.5 * alphaWebcam, Math.PI / 10),
      threshHold: Math.min(0.5 * alphaWebcam, Math.PI / 10),
      number: Math.abs(alphaVideo - alphaWebcam),
    };
  }

  compareVectorPose(webcamPoses, videoPoses, array) {
    let v1 = [
      webcamPoses[array[1]].x - webcamPoses[array[0]].x,
      webcamPoses[array[1]].y - webcamPoses[array[0]].y,
    ];
    let v2 = [
      videoPoses[array[1]].x - videoPoses[array[0]].x,
      videoPoses[array[1]].y - videoPoses[array[0]].y,
    ];

    let vectorAngle = Math.acos(
      (v1[0] * v2[0] + v1[1] * v2[1]) /
        (Math.sqrt(v1[0] * v1[0] + v1[1] * v1[1]) *
          Math.sqrt(v2[0] * v2[0] + v2[1] * v2[1]) +
          0.0000001)
    );
    if (array.length == 3) {
      v1 = [
        webcamPoses[array[1]].x - webcamPoses[array[2]].x,
        webcamPoses[array[1]].y - webcamPoses[array[2]].y,
      ];
      v2 = [
        videoPoses[array[1]].x - videoPoses[array[2]].x,
        videoPoses[array[1]].y - videoPoses[array[2]].y,
      ];

      let vectorAngle2 = Math.acos(
        (v1[0] * v2[0] + v1[1] * v2[1]) /
          (Math.sqrt(v1[0] * v1[0] + v1[1] * v1[1]) *
            Math.sqrt(v2[0] * v2[0] + v2[1] * v2[1]) +
            0.0000001)
      );
      vectorAngle += vectorAngle2;
      vectorAngle /= 2;
    }

    return {
      value: vectorAngle > Math.PI / 12,
      alphaWebcam: vectorAngle,
      alphaVideo: vectorAngle,
      valueCheck: vectorAngle,
      threshHold: Math.PI / 12,
      number: Math.abs(vectorAngle - Math.PI / 12),
    };
  }

  getAudio(poseError, videoAudios, lang) {
    let errorPosition = poseError.label;
    if (errorPosition in videoAudios && videoAudios[errorPosition]) {
      let audio_url = convertToFilename(videoAudios[errorPosition]);
      // console.log('url', audio_url)
      if (lang == "EN") {
        let eng_content = mapEngPath(videoAudios[errorPosition]);
        if (eng_content != undefined) {
          let audio_url2 = convertToEngFilename(eng_content);
          return audio_url2;
        } else {
          return ENG_DEFAULT_VOICE[errorPosition];
        }
      } else if (lang == "JP") {
        let jp_content = mapJpPath(videoAudios[errorPosition]);
        if (jp_content != undefined) {
          let audio_url3 = convertToJpFilename(jp_content);
          return audio_url3;
        } else {
          return JP_DEFAULT_VOICE[errorPosition];
        }
      }
      return audio_url;
    } else {
      // console.log('default voice', DEFAULT_VOICE[errorPosition]);
      if (lang == "EN") {
        return ENG_DEFAULT_VOICE[errorPosition];
      } else if (lang == "JP") {
        return JP_DEFAULT_VOICE[errorPosition];
      }
      return DEFAULT_VOICE[errorPosition];
    }
  }

  getPoseError(array) {
    // lấy bộ phận sai nhiều nhất
    let numError = array.filter((e) => e.value);
    let result = {} as { numError: number; label: string };

    if (numError.length) {
      let tmpMax = Math.max(...numError.map((e) => e.valueCheck));
      result = numError.find((e) => e.valueCheck === tmpMax);
      //result = numError[0];
    }
    result.numError = numError.length;
    return result;
  }

  getScorePractice(array) {
    // trả về điểm số của người tập
    let numError = array.filter((e) => e.value);
    let scorePractice = (100 * (array.length - numError.length)) / array.length;
    return scorePractice;
  }

  isLowScore(poses) {
    // score thấp thì không vẽ keypoint trên UI
    const lowPose = poses.slice(5).filter((e) => e.score < 0.2).length;
    return lowPose > 3;
  }

  checkCorrectPosition(webcamPoses, webcam) {
    if (
      webcamPoses[9].score < 0.2 ||
      webcamPoses[10].score < 0.2 ||
      webcamPoses[15].score < 0.2 ||
      webcamPoses[16].score < 0.2
    ) {
      return false;
    }

    const xArr = webcamPoses.map((e) => e.x);
    const yArr = webcamPoses.map((e) => e.y);
    const bboxUser = {
      x1: Math.min(...xArr) / webcam.videoWidth,
      y1: Math.min(...yArr) / webcam.videoHeight,
      x2: Math.max(...xArr) / webcam.videoWidth,
      y2: Math.max(...yArr) / webcam.videoHeight,
    };

    const SOverlap =
      Math.max(
        0,
        Math.min(bboxUser.x2, bboxSample.x2) -
          Math.max(bboxUser.x1, bboxSample.x1)
      ) *
      Math.max(
        0,
        Math.min(bboxUser.y2, bboxSample.y2) -
          Math.max(bboxUser.y1, bboxSample.y1)
      );

    const res =
      SOverlap /
        ((bboxSample.x2 - bboxSample.x1) * (bboxSample.y2 - bboxSample.y1) +
          (bboxUser.x2 - bboxUser.x1) * (bboxUser.y2 - bboxUser.y1) -
          SOverlap) >
      correctPositionThreshold;
    return res;
  }

  getVideoKeypoint() {}

  getWebcamKeypoint() {}
  getCompareResult() {}
  getIsCorrectPosition() {}
  getIsStaticVideo() {}
  getPoseReminder() {}

  getKeypointIndexBySide() {
    return poseDetection.util.getKeypointIndexBySide(this.model);
  }

  getAdjacentPairs() {
    return poseDetection.util.getAdjacentPairs(this.model);
  }

  dispose() {
    if (this.detector) this.detector.dispose();
  }
}

class VideoUtil {
  // Xử lý keypoint trên video
  videoUrl: string = null;
  videoKeypointStore: any[];
  focusedPoint: any[];
  chunkFileList: any[];
  segmentTime: number = null;
  videoName: string = null;
  keyPointHost: string = null;

  constructor() {
    this.videoUrl = null;
    this.videoKeypointStore = null;
    this.focusedPoint = null;
  }

  async init({ videoUrl, keyPointUrl }) {
    this.videoUrl = videoUrl;
    await this.getKeypoint(keyPointUrl);
  }

  async setVideoUrl(videoUrl, keypointUrl) {
    this.videoUrl = videoUrl;
    await this.getKeypoint(keypointUrl);
  }

  getFocusedPoint() {
    return this.focusedPoint;
  }

  async getKeypoint(videoUrl) {
    const match = videoUrl.match(/^(https?:\/\/[^\/]+)\//);
    this.keyPointHost = match ? match[1] : null;

    const arrVideo = videoUrl.split("/");
    this.videoName = arrVideo[arrVideo.length - 1]?.split(".")[0];
    if (this.videoName) {
      const data = (await keypointApi.getMetadata(this.keyPointHost, this.videoName)).data;

      this.focusedPoint = data?.focused_point;
      this.segmentTime = data?.segment_time;
      this.chunkFileList = data?.chunk_files;
      this.videoKeypointStore = new Array(data?.chunk_files.length).fill(null);
      
      this.videoKeypointStore[0] = (await keypointApi.getChunkFile(this.keyPointHost, this.videoName, this.chunkFileList[0])).data
    }
  }

 // Get current keypoint by time
 // optimized => using binary search
  async getKeypointMetaData(video: HTMLVideoElement) {
    // Return meta data keypoint at current time
    if (this.videoKeypointStore == null) return null;

    const currentChunkOrder = Math.floor(secondToMilisecond(video.currentTime) / this.segmentTime);
    const keypoint = this.searchKeypoint(this.videoKeypointStore[currentChunkOrder] , 
                                        roundToNearestNumber(secondToMilisecond(video.currentTime),33))
    
    return keypoint;
  }

  async getKeyPonitChunkFile(videoTime){
    const currentChunkOrder = Math.floor(secondToMilisecond(videoTime) / this.segmentTime);
    
    if(!this.videoKeypointStore[currentChunkOrder]){      
      this.videoKeypointStore[currentChunkOrder] = (await keypointApi.getChunkFile(this.keyPointHost, this.videoName, this.chunkFileList[currentChunkOrder])).data
    }
  }

  searchKeypoint = (list, targetTime) =>{
    if(list == null) return null;
    
    let left = 0;
    let right = list.length - 1;
  
  
    // Binary search
    while (left <= right) {
      const mid = Math.floor((left + right) / 2);
  
      // If the targetTime is found, return the corresponding object
      if (list[mid].time === targetTime) {
        return list[mid];
      }
  
      // Narrow down the search
      if (targetTime < list[mid].time) {
        right = mid - 1;
      } else {
        left = mid + 1;
      }
    }
  
    return null;
  }

  currentKeypoint(video: HTMLVideoElement, keypoint) {
    if (keypoint == undefined) return [];

    const videoWidth = video.videoWidth;
    const videoHeight = video.videoHeight;

    let res = {} as { score: number; keypoints: { x: number; y: number }[] };

    res["score"] = 0;
    res["keypoints"] = keypoint.keypoints.map((e) => {
      return {
        x: e.x * videoWidth,
        y: e.y * videoHeight,
        score: e.score,
      };
    });
    return [res];
  }

  currentState(keypoint) {
    if (keypoint == undefined) return false;
    if (keypoint?.compare === undefined) {
      return false;
    }
    if (keypoint.compare == undefined) return false;
    return keypoint.compare;
  }

  currentAudio(keypoint) {
    if (keypoint == undefined) return false;
    if (keypoint?.voice === undefined) {
      return null;
    }
    if (keypoint.voice == undefined) return null;
    return keypoint.voice;
  }
}

class LogUtil {
  // Xử lý log
  deviceId: string;
  videoUrl: string;

  constructor() {
    this.deviceId = null;
    this.videoUrl = null;
  }

  init({ deviceId, videoUrl }) {
    this.deviceId = deviceId;
    this.videoUrl = videoUrl;
  }

  async createLogFileKeypoint({ poses, poseError, audioName, file, video }) {
    if (!this.deviceId || !video) return;

    await sleep(100);

    const log = JSON.stringify({
      time: video.currentTime,
      deviceId: this.deviceId,
      videoId: this.videoUrl,
      poseValue: poses.map((e) => {
        return {
          name: e.label,
          threshold: e.threshHold,
          number: e.number,
          alphaVideo: e.alphaVideo,
          alphaWebcam: e.alphaWebcam,
        };
      }),
      poseError: poseError?.label,
      audioName: audioName,
    });

    const res = await keypointApi.createLogfile(log, file);
  }
}

export const customPoseDetection = new CustomPoseDetection();
