import UAParser from "ua-parser-js";

export const getUserAgentDetails = () => {
  const parser = new UAParser();
  const uaResult = parser.getResult();

  console.log(uaResult);

  const os = uaResult.os.name || "Unknown OS";
  const osVersion = uaResult.os.version || "Unknown Version";
  const device = uaResult.device.model || uaResult.ua;
  const browser = uaResult.browser.name || "Unknown Browser";
  const browserVersion = uaResult.browser.version || "Unknown Version";

  return {
    os: `${os} ${osVersion}`,
    device: device,
    browser: `${browser} ${browserVersion}`,
  };
};
