import { useEffect, useRef, useState, useMemo } from "react";
import "./utils/MultipleLang";
import Webcam from "react-webcam";
import { isMobile } from "react-device-detect";
import {
  MOVENET_CONFIGS,
  LINE_WIDTH,
  DEFAULT_RADIUS,
  VIDEO_CONFIGS,
} from "./config";
import "./App.css";
import { useLocation } from "react-router-dom";
import SockJS from "sockjs-client";
import { AUDIO_ACTION, handlePlayAudio } from "./utils/audio";
import trueBody from "./assets/images/true-body.png";
import falseBody from "./assets/images/false-body.png";
import checkedPng from "./assets/images/checked.png";
import { ReactComponent as VolumeOff } from "./assets/images/volume_off.svg";
import { ReactComponent as VolumeUp } from "./assets/images/volume_up.svg";
import { ReactComponent as Forward10 } from "./assets/images/forward10.svg";
import { ReactComponent as Reply10 } from "./assets/images/replay10.svg";
import { ReactComponent as Play } from "./assets/images/play.svg";
import { ReactComponent as Pause } from "./assets/images/pause.svg";
import { ReactComponent as Speedometer } from "./assets/images/speedometer.svg";

import { keypointApi, logApi } from "./utils/request";
import {
  Alert,
  Button,
  Modal,
  Slider,
  Spin,
  message,
  notification,
} from "antd";
import { FullscreenExitOutlined, FullscreenOutlined } from "@ant-design/icons";
import html2canvas from "html2canvas";
import { colors } from "./utils/color";
import { customPoseDetection } from "./libraries/custom-pose-detection/index";
import { getCurrentDateTime, sleep } from "./utils/time";
import { ScoreChart } from "components/Chart/ScoreChart";
import { useCountUp } from "hooks/useCountUp";
import dayjs from "dayjs";
import i18n, { Language } from "utils/MultipleLang";
import i18next from "i18next";
import ReactHlsPlayer from "react-hls-player";
import Hls from "hls.js";
import { getUserAgentDetails } from "utils/devide";
export enum WebviewEvent {
  VideoUpdate = "VIDEO_UPDATE",
  VideoEnd = "VIDEO_ENDED",
  VideoPaused = "VIDEO_PAUSED",
  VideoResume = "VIDEO_RESUME",
  VideoSpeedometer = "VIDEO_SPEEDOMETER",
  VideoScore = "VIDEO_SCORE",
  OutWebView = "OUT_WEB_VIEW",
  LogData = "LOG_DATA",
  VideoChangeAudio = "VIDEO_CHANGE_AUDIO",
  VideoAllowPlay = "VIDEO_ALLOW_PLAY",
  StartAudioEvent = "START_AUDIO_EVENT",
  VideoVolume = "VIDEO_VOLUME",
}

const YOGA_DEFAULT_IMAGE = "/yoga-default.png";
const pauseTimeOut = 3;
let detector;
let start_inference_time;
let num_of_inferences = 0;
let total_inference_time = 0;
let last_panel_update = 0;
let rafId: any = null;
let webcamCanvas: HTMLCanvasElement;
let webcamContext: CanvasRenderingContext2D;
let model;
let modelType;
let brain;
let poseLabel = "pose";
let text = "oki";
let poseScore = "0";
let lastPoseLabel;
let initTime = 0;
let audioTime = 0;
let logTime = 0; //thời gian gửi log
let initVideoPoses = [];
let isCorrectPosition = false;
let smooth = true; //làm mượt khi tập đúng, coi như tập đúng trong 3s tiếp theo
let smoothTimeOut;
let status = false; // lưu trạng thái tĩnh động gần nhất , false là động, true là tĩnh
let isInstructionDone = false; //khởi tạo thì chỉ phát audio hướng dẫn, sau khi audio chạy xong thì mới chạy tiếp
let videoPauseCount = 0;
let pauseTime = 0;
let canvas2;
let contex2: CanvasRenderingContext2D;
let errorThreshold = 2; // Số lỗi sai cho phép để pass qua động tác ở chế độ mặc định
/**
 * true: lật gương (giống như đang soi gương)
 */
let isMirror = false;

export enum YogaDifficulty {
  Easy = "easy",
  Medium = "medium",
  Hard = "hard",
}

function App() {
  const search = useLocation().search;
  const [cameraReady, setCameraReady] = useState(false);

  const webcamRef = useRef<Webcam>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const guidelineAudioRef = useRef<HTMLAudioElement>(null);
  const [lang, setLang] = useState(
    new URLSearchParams(search).get("lang")?.toUpperCase() || Language.Vi
  );

  const [videoQueryUrl, setVideoQueryUrl] = useState(
    new URLSearchParams(search).get("videoUrl")
  );
  const [keyPointUrl, setKeyPointUrl] = useState(
    new URLSearchParams(search).get("keyPointUrl")
  );

  const deviceQueryId = new URLSearchParams(search).get("deviceId");
  const videoFileQuery = new URLSearchParams(search).get("videoFile");
  const imageUrl =
    new URLSearchParams(search).get("imageUrl") || YOGA_DEFAULT_IMAGE;

  const osQuery = new URLSearchParams(search).get("os");
  const levelQuery = new URLSearchParams(search).get("level") as YogaDifficulty;

  const isDevMode = !window.location.hostname.includes("visedu.vn");
  const lastPausedAt = useRef(-1);
  const isPaused = useRef(false);
  const pauseVideoTimeout = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isCorrectPositionUI, setIsCorrectPositionUI] = useState(false);
  const tutorialAudio = useRef(new Audio());
  const [loadingTitle, setLoadingTitle] = useState("");
  const videoUpdateInterval = useRef(null);
  const canvasCameraRef = useRef<HTMLDivElement>(null);
  const [score, setScore] = useState<number>(null);
  const currentScore = useRef<number>(null);
  const [visibleChecked, setVisibleChecked] = useState(false);
  const currentVisibleChecked = useRef(false);
  const { count, setStart, setCountTo } = useCountUp({});
  const resumeAt = useRef(0);
  const [hlsInstance, setHlsInstance] = useState(null);
  const [error, setError] = useState(null);
  const [isHiddenVideo, setIsHiddenVideo] = useState(false);
  const langRef = useRef(lang);
  const playButtonRef = useRef<HTMLDivElement>(null);
  const pauseButtonRef = useRef<HTMLDivElement>(null);
  const playAudioButtonRef = useRef<HTMLDivElement>(null);
  currentVisibleChecked.current = visibleChecked;
  const [isMuted, setIsMuted] = useState(false);
  const [isPlayingVideo, setIsPlayingVideo] = useState(true);
  const previousTime = useRef(0);
  const maxScore = useRef<number>(null);
  const [isWebcamActive, setIsWebcamActive] = useState(true);
  const audioRef = useRef<HTMLVideoElement>(null);
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const audioUrl = useRef(null);

  const sendError = async (errorDetails) => {
    const userAgentDetails = getUserAgentDetails();
    try {
      logApi.createLog({
        logAI: { message: errorDetails, ...userAgentDetails },
      });
    } catch (apiError) {
      console.error("Failed to send error to the API", apiError);
    }
  };

  useEffect(() => {
    i18n.changeLanguage(lang.toLowerCase());
    setLoadingTitle(i18next.t("video.loading"));
  }, [lang]);

  //*interval update video
  useEffect(() => {
    //interval 5s
    videoUpdateInterval.current = setInterval(() => {
      if (videoRef.current) {
        console.log("emit video update");
        console.log(videoRef.current.currentTime);
        const currentTime = videoRef.current.currentTime;
        const watchedDuration = currentTime - previousTime.current; // Tính thời gian đã xem
        if (watchedDuration > 0) {
          emitEventToWebview(WebviewEvent.VideoUpdate, {
            seconds: watchedDuration,
          });
        }

        previousTime.current = currentTime;
      }
    }, 5 * 1000);

    setInterval(() => {
      if (videoRef.current) {
        customPoseDetection
          .getVideoUtil()
          .getKeyPonitChunkFile(videoRef.current.currentTime);
      }
    }, 2 * 1000);

    return () => {
      clearInterval(videoUpdateInterval.current);
    };
  }, []);

  //* init, check video params
  useEffect(() => {
    if (videoQueryUrl) {
      init();
    } else {
      alert("Video không hợp lệ");
    }

    const messageListener = window.addEventListener(
      "message",
      (message) => {
        if (!message.data || typeof message.data != "string") {
          return;
        }

        const data = JSON.parse(message.data);

        if (data?.type == "VIDEO_PLAYBACK" && videoRef.current) {
          videoRef.current.playbackRate = data.data;
        }

        if (data?.type == WebviewEvent.VideoAllowPlay) {
          console.log({ videoAllowPlay: data.data });
          handleVideoAllowPlay(data.data);
        }

        if (data?.type == WebviewEvent.VideoChangeAudio) {
          console.log({ VideoChangeAudio: data.data });

          handleVideoChangeAudio(data.data);
        }
        if (data?.type == WebviewEvent.VideoVolume) {
          console.log({ VideoVolume: data.data });
          handleChangeVolumeAudio(data.data);
        }
      },
      true
    );

    return messageListener;
  }, []);

  const getAudioQuyTrinh = () => {
    let src = "";
    switch (langRef.current) {
      case Language.Vi:
        src = AUDIO_ACTION.QUY_TRINH;
        break;

      case Language.En:
        src = AUDIO_ACTION.QUY_TRINH_TA;
        break;
      case Language.JP:
        src = AUDIO_ACTION.QUY_TRINH_JP;
        break;

      default:
        src = AUDIO_ACTION.QUY_TRINH;
        break;
    }

    return src;
  };

  const init = async () => {
    console.log("=== Start load Model ===", getCurrentDateTime());
    const startTime = dayjs();
    console.log("=== Start load Model ===", startTime.format("HH:mm:ss"));

    customPoseDetection
      .initPoseUtil()
      .then(() => {
        console.log("=== Model is loaded ===", getCurrentDateTime());
      })
      .catch(async ({ message }) => {
        await sendError(message);
        console.log(`=== Error model === ${getCurrentDateTime()}`, message);
      })
      .finally(async () => {
        const endTime = dayjs();
        const loadTime = endTime.diff(startTime, "second", true).toFixed(2); // Tính thời gian tải (giây)
        const data = {
          startTime: startTime.format("HH:mm:ss"),
          endTime: endTime.format("HH:mm:ss"),
          loadTime,
        };
        try {
          logApi.createLog({
            logTime: JSON.stringify(data),
          });
        } catch (apiError) {
          console.error("Failed to send error to the API", apiError);
        }
      });

    //* load audio
    tutorialAudio.current.autoplay = true;
    tutorialAudio.current.src = getAudioQuyTrinh();
    tutorialAudio.current.addEventListener("ended", () => {
      handleAudioEnded();
    });
  };

  //* gọi xử lý khi đã init xog model và video [DONE MODEL, VIDEO]
  useEffect(() => {
    i18n.changeLanguage(lang.toLowerCase());
    setLoadingTitle(i18n.t("ai.init"));
    console.log("=== model and video ready ===");
    _loadPoseNet();
    loadLevel(levelQuery); // load độ nhạy của trợ lý ảo
  }, []);

  const [socket, setSocket] = useState(null);

  //* run socket
  useEffect(() => {
    // Create a WebSocket connection
    console.log("chạy socket");
    const ws = new SockJS("https://admin.visedu.vn:10085/websocket");

    ws.onopen = () => {
      console.log("WebSocket connection opened.");
      setSocket(ws);
    };
    // Code subcribe socket de chay dieu khien am thanh tu xa
    ws.onmessage = (event) => {
      //console.log(event.data)
      handlePlayAudio(event.data);
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed.");
    };

    return () => {
      // Clean up WebSocket connection on component unmount
      if (socket) {
        socket.close();
      }
    };
  }, []);

  // Lấy dữ liệu từ params url
  const [deviceId, setDeviceId] = useState(deviceQueryId);

  const _loadPoseNet = async () => {
    console.log("=== START LOAD POSE NET ===", getCurrentDateTime());
    if (rafId) {
      window.cancelAnimationFrame(rafId);
      customPoseDetection.dispose();
    }

    await customPoseDetection.init({ videoUrl: videoQueryUrl, keyPointUrl });
    requestAnimationFrame(async () => {
      await renderPrediction();
      console.log("=== END LOAD POSE NET ===", getCurrentDateTime());

      setIsLoading(false);
    });
  };

  const loadLevel = async (levelQuery) => {
    if (levelQuery == "easy") {
      errorThreshold = 3;
    }
    if (levelQuery == "medium" || levelQuery == null) {
      errorThreshold = 2;
    }
    if (levelQuery == "hard") {
      errorThreshold = 1;
    }
  };

  const renderPrediction = async () => {
    await renderResult();
    rafId = requestAnimationFrame(renderPrediction);
  };

  const handleVideoLoaded = (ev) => {
    console.log("=== VIDEO LOADED ===", getCurrentDateTime());
    if (isPlayingAudio) {
      console.log("vao audio");
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  };

  const handleVideoError = (err) => {
    console.error("handleVideoError:", err);
    setError(JSON.stringify(err));
    alert("Video bị lỗi");
  };

  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      isPaused.current = true;

      console.log("=== PAUSED VIDEO === ", {
        current: videoRef.current.currentTime,
      });

      if (isCorrectPosition) {
        //10s sau sẽ tự play
        pauseVideoTimeout.current = setTimeout(() => {
          videoRef.current.play();
          audioRef.current.play();
        }, pauseTimeOut * 1000);
      }
    }
  };

  const resumeVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();

      isPaused.current = false;

      clearTimeout(pauseVideoTimeout.current);
    }
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  const renderResult = async () => {
    const webcam = webcamRef.current && webcamRef.current["video"];
    const video = videoRef.current && videoRef.current;

    //video.load();

    if (!cameraReady && !webcam) {
      return;
    }

    if (webcam.readyState < 2) {
      return;
    }

    if (!video) {
      return;
    }

    if (video?.readyState < 2) {
      return;
    }

    //TODO: tạo canvas lật ngược
    const canvas = makeCanvas(webcam);

    const detectData = await customPoseDetection.detect(canvas, video, lang);
    const {
      webcamPoses,
      videoPoses,
      scoreCompare,
      audioTag,
      isStop,
      isCorrectPratice,
      scorePractice,
    } = detectData;

    //TODO: remove canvas after detect
    canvas.remove();

    getContexFullScreen(webcam);
    getVideoContexFullScreen(video);

    if (currentScore.current != scorePractice) {
      console.log("scorePractice:", scorePractice);

      if (typeof scorePractice == "number") {
        setStart(true);
        setCountTo(scorePractice);
      }

      const previousScore = currentScore.current;

      // Kiểm tra nếu scorePractice lớn hơn maxScore
      if (scorePractice > maxScore.current) {
        maxScore.current = scorePractice; // Cập nhật maxScore
        emitEventToWebview(WebviewEvent.VideoScore, { scorePractice }); // Gửi event khi có score lớn hơn
      }

      // Cập nhật currentScore
      currentScore.current = scorePractice;

      setScore(scorePractice);

      // console.log("Điểm trước đó:", previousScore);
      // console.log("Điểm sau đó:", scorePractice);
      // console.log("Điểm cao nhất hiện tại:", maxScore.current);
    }

    /* 
        //TODO:
        - audioTag: dựa vào tag để phát audio tương ứng trong thời gian chạy
        - kiểm tra isStop: nếu bằng true thì dừng video, nếu bằng false/null thì cho chạy tiếp
        - isCorrectPratice: nếu = null thì chưa so sánh, vẽ màu trắng, nếu = true thì tập đúng, vẽ màu xanh, nếu = false thì vẽ màu đỏ
        */

    if (isCorrectPratice != null) {
      //trước đó chưa pause và thời điểm pause gần nhất cách hiện tại ít nhất 2 giây
      if (isStop && !isPaused.current && dayjs().unix() - pauseTime > 2) {
        emitEventToWebview(WebviewEvent.VideoPaused);
        pauseVideo();
        resumeAt.current = dayjs().unix();
        pauseTime = resumeAt.current;
        console.log("Detection data", {
          detectData,
          isStop,
          audioTag,
          isPaused: isPaused.current,
        });
      }

      //đã pause và giờ tiếp tục
      const current = dayjs().unix();
      if (isPaused.current && current - resumeAt.current > pauseTimeOut) {
        emitEventToWebview(WebviewEvent.VideoResume);
        resumeVideo();
        setVisibleChecked(false);
      }
    }

    //

    let score = {} as { numError: number; label: string };

    //dừng lại để compare
    if (
      videoPoses.length > 0 &&
      webcamPoses.length > 0 &&
      isStop
      /* && !isLowScore(videoPoses[0]["keypoints"]) */
    ) {
      //isLowScore(videoPoses[0]["keypoints"]);
      //console.log("isLowScore: ", isLowScore(videoPoses[0]["keypoints"]));
      let compare = scoreCompare;

      score = customPoseDetection.getPoseUtil().getPoseError(compare);

      //console.log("score", score);

      //check để hiển thị tập đúng
      if (
        isPaused.current &&
        !currentVisibleChecked.current &&
        score.numError <= errorThreshold
      ) {
        setVisibleChecked(true);
        resumeVideo();
        setTimeout(() => {
          setVisibleChecked(false);
        }, 3 * 1000); // Dấu tích V xanh hiện trong 1 giây
      }

      let now = (performance || Date).now();
      if (!isCorrectPosition) audioTime = now;

      if (now - initTime >= 1000) {
        initTime = now;
      }

      if (now - logTime >= 5000) {
        createLogFileKeypoint(compare, score, null);
        logTime = now;
      }

      if (now - audioTime >= 5000) {
        if (
          // score?.label /* && isVideoStatic */ &&
          score.numError > errorThreshold &&
          isCorrectPosition &&
          isInstructionDone
        ) {
          // if (score?.label && isVideoStatic && isCorrectPosition && isInstructionDone) {

          //* play audio
          if (audioTag) {
            handlePlayAudio(audioTag);
          }
          // customPoseDetection.getLogUtil().createLogFileKeypoint({
          //     poses: compare,
          //     poseError: score,
          //     video: video,
          //     audioName: AUDIO_ACTION[score?.label],
          //     file: await handleCaptureAndResize()
          // })
          createLogFileKeypoint(compare, score, audioTag);
          audioTime = now;

          //nếu sai sẽ dừng 3s để check, nếu đúng thì chạy tiếp
          // video.pause();
          // setTimeout(() => {
          //     videoPauseCount++;
          // }, 3000)
        }
      }
    }

    //đổi màu khung webcam
    handleWebcamBorder(isStop, score, smooth);

    if (webcamPoses.length > 0) {
      if (isCorrectPosition == false && isInstructionDone) {
        if (checkCorrectPosition(webcamPoses[0]["keypoints"], webcam)) {
          console.log("da dung position");
          // videoRef.current.play();
          videoRef.current.muted = false;
          audioRef.current.muted = false;
          // playButtonRef?.current?.click();
          playButtonRef?.current?.click();
          playAudioButtonRef?.current?.click();
          //video?.play();
          //video.muted = false;
        }
      }

      //TODO: lật 180đ
      getResultsFullScreen(webcamPoses, score, isStop);
    }

    if (
      videoPoses.length > 0 &&
      !customPoseDetection.getPoseUtil().isLowScore(videoPoses[0]["keypoints"])
    ) {
      getResultsVideoFullScreen(videoPoses);
    }
  };

  const handleUserInteraction = () => {
    videoRef.current.play();
    audioRef.current.play();
  };

  const startEstimatePoses = () => {
    start_inference_time = (performance || Date).now();
  };

  const stopEstimatePoses = () => {
    const endInferenceTime = (performance || Date).now();
    total_inference_time += endInferenceTime - start_inference_time;
    ++num_of_inferences;
    const panelUpdateMilliseconds = 1000;

    if (endInferenceTime - last_panel_update >= panelUpdateMilliseconds) {
      const averageInferenceTime = total_inference_time / num_of_inferences;
      total_inference_time = 0;
      num_of_inferences = 0;
      //setDisplayFps(1000.0 / averageInferenceTime, 120);
      last_panel_update = endInferenceTime;
    }
  };

  const webcamRatio = 0.7;
  const videoRatio = 0.45;

  /**
   * tạo canvas từ thẻ video
   */
  const makeCanvas = (video: HTMLVideoElement) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const videoWidth = video.videoWidth;
    const videoHeight = video.videoHeight;

    canvas.width = videoWidth * webcamRatio;
    canvas.height = videoHeight * webcamRatio;
    // canvas.height = 203;
    // canvas.width = videoWidth * (203 / videoHeight);
    ctx.fillRect(0, 0, videoWidth, videoHeight);
    // webcamContext.translate(video.videoWidth * webcamRatio, 0);

    if (!isMirror) {
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    } else {
      ctx.translate(video.videoWidth * webcamRatio, 0);
      ctx.scale(-webcamRatio, webcamRatio);
      ctx.drawImage(video, 0, 0, videoWidth, videoHeight);
    }

    return canvas;
  };

  /**
   * vẽ trên web-cam
   */
  const getContexFullScreen = (video: HTMLVideoElement) => {
    webcamCanvas =
      webcamCanvas || (document.getElementById("canvas") as HTMLCanvasElement);
    webcamContext = webcamContext || webcamCanvas.getContext("2d");

    const videoWidth = video.videoWidth;
    const videoHeight = video.videoHeight;

    video.width = videoWidth;
    video.height = videoHeight;

    webcamCanvas.width = videoWidth * webcamRatio;
    webcamCanvas.height = videoHeight * webcamRatio;
    // canvas.height = 203;
    // canvas.width = videoWidth * (203 / videoHeight);
    webcamContext.fillRect(0, 0, videoWidth, videoHeight);
    // webcamContext.translate(video.videoWidth * webcamRatio, 0);

    if (!isMirror) {
      webcamContext.drawImage(
        video,
        0,
        0,
        webcamCanvas.width,
        webcamCanvas.height
      );
    } else {
      webcamContext.translate(video.videoWidth * webcamRatio, 0);
      webcamContext.scale(-webcamRatio, webcamRatio);
      webcamContext.drawImage(video, 0, 0, videoWidth, videoHeight);
    }
  };

  const getResultsFullScreen = (poses, score, isStop: boolean) => {
    for (const pose of poses) {
      //getResults(pose);
      if (pose?.keypoints != undefined && pose?.keypoints != null) {
        getKeyPoints(pose.keypoints);
        drawSkeleton(pose.keypoints, score, isStop);
      }
    }
  };

  const getKeyPoints = (keypoints) => {
    const keypointInd = customPoseDetection
      .getPoseUtil()
      .getKeypointIndexBySide();
    webcamContext.fillStyle = "#fff";
    webcamContext.strokeStyle = "#fff";
    webcamContext.lineWidth = LINE_WIDTH;

    for (const i of keypointInd.middle) {
      getKeyPoint(keypoints[i]);
    }

    //contex2d.fillStyle = '#00ff00';

    for (const i of keypointInd.left) {
      getKeyPoint(keypoints[i]);
    }

    //contex2d.fillStyle = '#ffff00';

    for (const i of keypointInd.right) {
      getKeyPoint(keypoints[i]);
    }
  };

  const getKeyPoint = (keypoint) => {
    // If score is null, just show the keypoint.
    const score = keypoint.score != null ? keypoint.score : 1;
    const scoreThreshold = MOVENET_CONFIGS.scoreThreshold || 0;

    if (score >= scoreThreshold) {
      const circle = new Path2D();
      circle.arc(keypoint.x, keypoint.y, 4, 0, 2 * Math.PI);
      webcamContext.fill(circle);
      webcamContext.stroke(circle);
    }
  };

  const drawSkeleton = (keypoints, score, isStop: boolean) => {
    webcamContext.fillStyle = "White";
    webcamContext.strokeStyle = !isStop
      ? "White"
      : score.numError <= errorThreshold
      ? "#24ba06"
      : "#cc160c";
    //contex2d.strokeStyle = '#24ba06';
    webcamContext.lineWidth = LINE_WIDTH;
    customPoseDetection
      .getPoseUtil()
      .getAdjacentPairs()
      .forEach(([i, j]) => {
        //contex2d.strokeStyle = '#24ba06';
        const keypoint1 = keypoints[i];
        const keypoint2 = keypoints[j]; // If score is null, just show the keypoint.

        const score1 = keypoint1.score != null ? keypoint1.score : 1;
        const score2 = keypoint2.score != null ? keypoint2.score : 1;
        const scoreThreshold = MOVENET_CONFIGS.scoreThreshold || 0;

        //if (!isVideoStatic) contex2d.strokeStyle = 'White';

        if (
          score.numError > 0 &&
          checkPairInArray([i, j], score.array) &&
          isStop
        )
          webcamContext.strokeStyle = "#cc160c";

        if (score1 >= scoreThreshold && score2 >= scoreThreshold) {
          webcamContext.beginPath();
          webcamContext.moveTo(keypoint1.x, keypoint1.y);
          webcamContext.lineTo(keypoint2.x, keypoint2.y);
          webcamContext.stroke();
        }

        if (score.numError == 0 && isStop)
          webcamContext.strokeStyle = "#24ba06";
      });
  };

  const checkPairInArray = (pair, array) => {
    return array.includes(pair[0]) && array.includes(pair[1]);
  };

  const onUserMediaError = (e) => {
    console.log("ERROR Occured", e);
    Modal.confirm({
      title: "Cảnh báo",
      content:
        "Có vẻ như bạn đã từ chối quyền truy cập Camera? Bạn muốn cho phép lại quyền Camera không?",
      cancelText: "Không",
      okText: "Cho phép",
      onOk() {
        window.location.reload();
      },
      onCancel() {
        emitEventToWebview(WebviewEvent.OutWebView);
        // message.warning(
        //   "Tính năng này chỉ hoạt động khi có quyền truy cập Camera"
        // );
      },
    });
  };

  const onUserMedia = (s) => {
    console.log("Camera loaded!", s);
    setCameraReady(true);
  };

  // video skeleton
  const getVideoContexFullScreen = (video) => {
    canvas2 = document.getElementById("canvas2") as HTMLCanvasElement;
    contex2 = canvas2.getContext("2d");

    const videoWidth = video.videoWidth;
    const videoHeight = video.videoHeight;

    video.width = videoWidth;
    video.height = videoHeight;

    canvas2.width = videoWidth * videoRatio;
    canvas2.height = videoHeight * videoRatio;
    contex2.fillRect(0, 0, videoWidth, videoHeight);

    contex2.translate(video.videoWidth * videoRatio, 0);
    contex2.scale(-videoRatio, videoRatio);
    contex2.drawImage(video, 0, 0, videoWidth, videoHeight);
  };

  const getResultsVideoFullScreen = (poses) => {
    for (const pose of poses) {
      if (pose?.keypoints != undefined && pose?.keypoints != null) {
        let inputs = [];
        for (let i = 0; i < pose.keypoints.length; i++) {
          let x = pose.keypoints[i].x;
          let y = pose.keypoints[i].y;
          inputs.push(x);
          inputs.push(y);
        }
        getVideoKeyPoints(pose.keypoints);
        drawVideoSkeleton(pose.keypoints);
      }
    }
  };

  const getVideoKeyPoints = (keypoints) => {
    const keypointInd = customPoseDetection
      .getPoseUtil()
      .getKeypointIndexBySide();
    contex2.fillStyle = "#fff";
    contex2.strokeStyle = "#fff";
    contex2.lineWidth = LINE_WIDTH;

    for (const i of keypointInd.middle) {
      getVideoKeyPoint(keypoints[i]);
    }

    //contex2.fillStyle = '#00ff00';

    for (const i of keypointInd.left) {
      getVideoKeyPoint(keypoints[i]);
    }

    //contex2.fillStyle = '#ffff00';

    for (const i of keypointInd.right) {
      getVideoKeyPoint(keypoints[i]);
    }
  };

  const getVideoKeyPoint = (keypoint) => {
    // If score is null, just show the keypoint.
    const score = keypoint.score != null ? keypoint.score : 1;
    const scoreThreshold = MOVENET_CONFIGS.scoreThreshold || 0;

    if (score >= scoreThreshold) {
      const circle = new Path2D();
      circle.arc(keypoint.x, keypoint.y, DEFAULT_RADIUS, 0, 2 * Math.PI);
      contex2.fill(circle);
      contex2.stroke(circle);
    }
  };

  const drawVideoSkeleton = (keypoints) => {
    contex2.fillStyle = "White";
    contex2.strokeStyle = "White";
    contex2.lineWidth = LINE_WIDTH;
    customPoseDetection
      .getPoseUtil()
      .getAdjacentPairs()
      .forEach(([i, j]) => {
        const keypoint1 = keypoints[i];
        const keypoint2 = keypoints[j]; // If score is null, just show the keypoint.

        const score1 = keypoint1.score != null ? keypoint1.score : 1;
        const score2 = keypoint2.score != null ? keypoint2.score : 1;
        const scoreThreshold = MOVENET_CONFIGS.scoreThreshold || 0;

        if (score1 >= scoreThreshold && score2 >= scoreThreshold) {
          contex2.beginPath();
          contex2.moveTo(keypoint1.x, keypoint1.y);
          contex2.lineTo(keypoint2.x, keypoint2.y);
          contex2.stroke();
        }
      });
  };

  // START kiếm tra vị trí đứng
  const checkCorrectPosition = (webcamPoses, webcam) => {
    const res = customPoseDetection
      .getPoseUtil()
      .checkCorrectPosition(webcamPoses, webcam);
    if (res) {
      isCorrectPosition = true;
      setIsCorrectPositionUI(true);
      setTimeout(() => {
        setIsCorrectPositionUI(null);
      }, 5000);
    }
    return res;
  };

  // END kiếm tra vị trí đứng

  const handleVideoSeek = (number) => {
    handleShowVideoControl();
    setVideoSeek(number);
    videoRef.current.currentTime = Math.round(
      (number * videoRef.current.duration) / 100
    );
    hlsInstance.startLoad();
  };

  const onTimeUpdate = () => {
    const number = Math.round(
      (videoRef.current.currentTime * 100) / videoRef.current.duration
    );
    setVideoSeek(number);
  };

  const [videoSeek, setVideoSeek] = useState(0);
  const [isShowVideoControl, setIsShowVideoControl] = useState(false);

  const handleShowVideoControl = () => {
    //if (isCorrectPositionUI != null) return
    if (isShowVideoControl == false) {
      setIsShowVideoControl(true);
      setTimeout(() => {
        setIsShowVideoControl(false);
      }, 4000);
    }
  };

  const emitEventToWebview = async (event: WebviewEvent, data?: any) => {
    //@ts-ignore
    if (window && window.ReactNativeWebView) {
      try {
        let dataPost: any = {
          event,
        };

        if (typeof data == "object") {
          dataPost.data = data;
        }

        //@ts-ignore
        window.ReactNativeWebView.postMessage(JSON.stringify(dataPost));

        window.postMessage(JSON.stringify(dataPost));
      } catch ({ message }) {
        await sendError(message);
        console.error(`Emit webview error:`, error);
      }
    }
  };

  // bắn sự kiện video kết thúc
  const onEnd = () => {
    emitEventToWebview(WebviewEvent.VideoEnd);
  };

  const onVideoSpeedometer = () => {
    emitEventToWebview(WebviewEvent.VideoSpeedometer);
  };

  //Nhận data từ app
  const handleReceiveMessage = async (message) => {
    if (!message.data || typeof message.data != "string") {
      return;
    }

    const { videoLink, videoFilePath, keyPointUrl } = JSON.parse(message.data);
    const data = {
      videoLink,
      videoFilePath,
      keyPointUrl,
      isHiddenVideo,
      isWebcamActive,
      audioUrl: audioUrl ? audioUrl : "Không có url",
    };

    if (videoLink && keyPointUrl && videoRef.current) {
      try {
        videoRef.current.pause();
        audioRef.current.pause();
        await sleep(2000);

        setIsHiddenVideo(true);
        setIsWebcamActive(false);
        setVideoQueryUrl(videoLink);
        setKeyPointUrl(keyPointUrl);

        //đang play
        // if (!videoRef.current.paused) {
        //   videoRef.current.pause();
        // }

        console.log("data nhass", JSON.stringify(data));
        await customPoseDetection.setNextVideo(videoLink, keyPointUrl);
        // if (videoRef.current.src !== videoLink) {
        //   videoRef.current.src = videoLink;
        //   videoRef.current.currentTime = 0; // Reset the video time
        // }
        videoRef.current.src = videoLink;
        videoRef.current.load();
        audioRef.current.load();
        videoRef.current.currentTime = 0;
        maxScore.current = 0;
        if (isCorrectPosition) {
          videoRef.current.play();
          setIsPlayingVideo(true);
        }
      } catch ({ message }) {
        console.log("Lỗi nè", message);
        await sendError(message);
      } finally {
        setTimeout(() => {
          requestAnimationFrame(() => {
            setIsHiddenVideo(false);
          });
          if (isPlayingAudio) {
            console.log("play audio r");
            audioRef.current.play();
            setIsPlayingAudio(true);
          }
        }, 3000);
        setIsWebcamActive(true);
      }
    }
  };

  const handleVideoAllowPlay = (isPlaying) => {
    setIsPlayingAudio(isPlaying);
    if (!isPlaying && audioRef.current) {
      console.log("vao pause");
      audioRef.current.pause(); // Tạm dừng nếu không được phép phát
    } else {
    }
  };

  const handleChangeVolumeAudio = (volume) => {
    audioRef.current.volume = volume;
  };

  const handleVideoChangeAudio = (url) => {
    audioUrl.current = url; // Cập nhật URL audio
    console.log("Audio URL đã thay đổi:", url);
  };

  // Theo dõi thay đổi của audioUrl và isPlayingAudio
  useEffect(() => {
    if (isPlayingAudio && audioUrl.current && isCorrectPosition) {
      audioRef.current.src = audioUrl.current;
      audioRef.current.play();
    }
  }, [audioUrl.current, isPlayingAudio, isCorrectPosition]);

  useEffect(() => {
    document.addEventListener("message", handleReceiveMessage);
    window.addEventListener("message", handleReceiveMessage);

    return () => {
      document.removeEventListener("message", handleReceiveMessage);
      window.removeEventListener("message", handleReceiveMessage);
    };
  }, []);

  // bắt sự kiện audio hướng dẫn kết thúc
  const handleAudioEnded = () => {
    console.log("Audio ended, do something else...");
    // Thực hiện các tác vụ khác sau khi âm thanh kết thúc
    //videoRef.current.play();
    //videoRef.current.muted = false;
    isInstructionDone = true;
    console.log("isInstructionDone", isInstructionDone);

    if (guidelineAudioRef.current) {
      guidelineAudioRef.current.muted = true;
    }

    if (tutorialAudio.current) {
      tutorialAudio.current.muted = true;
    }
  };

  const isVideoPlaying = (video) =>
    !!(
      video.currentTime > 0 &&
      !video.paused &&
      !video.ended &&
      video.readyState > 2
    );

  useEffect(() => {
    // setTimeout(() => {
    //   setIsHiddenVideo(true);
    // }, 3000);

    // setTimeout(() => {
    //   setIsHiddenVideo(false);
    // }, 6000);

    setTimeout(() => {
      if (isVideoPlaying(videoRef.current)) videoRef.current.pause();
    }, 3000);
    document.addEventListener("click", playAudio);
    return () => {
      document.removeEventListener("click", playAudio);
    };
  }, []);

  const playAudio = () => {
    // console.log("click");
    if (guidelineAudioRef.current) {
      guidelineAudioRef.current.play();
    }
  };

  const playVideo = () => {
    videoRef.current.play();
  };

  //Chuyển đổi giao diện
  const [isZoomOut, setIsZoomOut] = useState(true);
  const handleZoomOut = () => {
    setIsZoomOut(!isZoomOut);
  };

  //Screenshot màn hình
  const contentRef = useRef(null);

  const resizeImage = (canvas, newWidth, newHeight) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = canvas.toDataURL("image/png");

      img.onload = () => {
        const tmpCanvas = document.createElement("canvas");
        const tmpCtx = tmpCanvas.getContext("2d");

        tmpCanvas.width = newWidth;
        tmpCanvas.height = newHeight;

        // Vẽ ảnh đã load vào canvas mới với kích thước mới
        tmpCtx.drawImage(img, 0, 0, newWidth, newHeight);

        // Chuyển canvas mới thành đối tượng Blob
        tmpCanvas.toBlob((blob) => {
          const file = new File([blob], "screenshot.png", {
            type: "image/png",
          });
          resolve(file);
        }, "image/png");
      };
    });
  };

  const handleCaptureAndResize = async () => {
    if (contentRef.current) {
      const canvasEle = await html2canvas(contentRef.current, {
        logging: false,
      });
      const file = await resizeImage(
        canvasEle,
        canvasEle.width * 0.3,
        canvasEle.height * 0.3
      );
      return file;
    }
    return null;
  };

  const createLogFileKeypoint = async (poses, poseError, audioName) => {
    if (!deviceQueryId || !videoRef?.current) return;

    await sleep(100);
    const file = await handleCaptureAndResize();

    const log = JSON.stringify({
      time: videoRef.current.currentTime,
      deviceId: deviceQueryId,
      videoId: videoQueryUrl,
      poseValue: poses.map((e) => {
        return {
          name: e.label,
          threshold: e.threshHold,
          number: e.number,
          alphaVideo: e.alphaVideo,
          alphaWebcam: e.alphaWebcam,
        };
      }),
      poseError: poseError?.label,
      audioName: audioName,
    });

    const res = await keypointApi.createLogfile(log, file);
  };

  //Đổi màu khung hình người tập
  const handleWebcamBorder = (isStop, score, smooth) => {
    if (canvasCameraRef.current) {
      let color = "";

      if (!isStop) {
        color = colors.normal;
      } else {
        if (score.numError <= errorThreshold) {
          color = colors.correctExercise;
        } else {
          color = colors.failExercise;
        }
      }
      canvasCameraRef.current.style.borderColor = color;
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement && Hls.isSupported()) {
      const hls = new Hls();
      setHlsInstance(hls);

      let fragmentsLoaded = 0;
      const initialFragmentsToLoad = 1;
      const subsequentFragmentsToLoad = 4;

      let isInitialLoadComplete = false;
      let loadMoreFragments = false;

      hls.on(Hls.Events.FRAG_LOADED, (event, data) => {
        const fragment = data.frag;
        // Get keypoint chunk file by end time of the fragment in seconds
        customPoseDetection.getVideoUtil().getKeyPonitChunkFile(fragment.end);

        fragmentsLoaded += 1;

        if (
          !isInitialLoadComplete &&
          fragmentsLoaded === initialFragmentsToLoad
        ) {
          isInitialLoadComplete = true;
          hls.stopLoad();
        } else if (isInitialLoadComplete) {
          if (fragmentsLoaded % subsequentFragmentsToLoad === 0) {
            loadMoreFragments = false;
            hls.stopLoad(); // Dừng tải sau khi đã tải thêm 4 fragment
          }
        }
      });

      hls.loadSource(videoQueryUrl);
      hls.attachMedia(videoElement);

      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        if (isCorrectPosition) videoElement.play();
      });
      // Sự kiện khi video bắt đầu phát
      videoElement.onplay = () => {
        if (isInitialLoadComplete && loadMoreFragments) {
          hls.startLoad();
        }
      };

      // Theo dõi thời gian phát video
      videoElement.ontimeupdate = () => {
        const currentTime = videoElement.currentTime;
        const bufferEnd = videoElement.buffered.length
          ? videoElement.buffered.end(0)
          : 0;

        // Khi buffer sắp hết, bắt đầu tải thêm fragment
        if (
          bufferEnd - currentTime < 10 &&
          !loadMoreFragments &&
          isInitialLoadComplete
        ) {
          loadMoreFragments = true;
          hls.startLoad();
        }
      };

      // Cleanup khi component bị gỡ bỏ
      return () => {
        hls.destroy();
        setHlsInstance(null);
      };
    }
  }, [videoQueryUrl]);

  const seekVideo = (seconds) => {
    videoRef.current.currentTime += seconds;
  };

  const handleAlert = (message) => {
    alert(message);
  };

  const toggleMute = () => {
    if (videoRef.current.muted) {
      videoRef.current.muted = false;
      setIsMuted(false);
    } else {
      videoRef.current.muted = true;
      setIsMuted(true);
    }
  };

  const changePlaybackSpeed = (speed) => {
    videoRef.current.playbackRate = speed;
  };

  const playPauseVideo = () => {
    if (isPlayingVideo) {
      // Pause video and clear intervals
      videoRef.current.pause();

      setIsPlayingVideo(false);
      clearInterval(videoUpdateInterval.current); // Clear the video update interval
      // Lưu lại thời gian trước khi tạm dừng video
      previousTime.current = videoRef.current.currentTime;
    } else {
      // Play video and set intervals again
      videoRef.current.play();
      setIsPlayingVideo(true);

      // Recreate the intervals when video is playing
      videoUpdateInterval.current = setInterval(() => {
        if (videoRef.current) {
          const currentTime = videoRef.current.currentTime;
          const watchedDuration = currentTime - previousTime.current;
          console.log(videoRef.current.currentTime);
          console.log(watchedDuration);
          if (watchedDuration > 0) {
            emitEventToWebview(WebviewEvent.VideoUpdate, {
              seconds: watchedDuration,
            });
          }

          // Cập nhật lại previousTime
          previousTime.current = currentTime;
        }
      }, 5 * 1000);

      setInterval(() => {
        if (videoRef.current) {
          customPoseDetection
            .getVideoUtil()
            .getKeyPonitChunkFile(videoRef.current.currentTime);
        }
      }, 2 * 1000);
    }
  };

  useEffect(() => {
    const handleTouchStart = () => {
      if (videoRef.current && audioRef.current) {
        videoRef.current.play();
        audioRef.current.play();
      }
      document.removeEventListener("touchstart", handleTouchStart);
    };

    document.addEventListener("touchstart", handleTouchStart);
    Modal.confirm({
      cancelButtonProps: { style: { display: "none" } },
      title: i18next.t("videoPopup.title"),
      content: i18next.t("videoPopup.content"),
      cancelText: i18next.t("videoPopup.cancelText"),
      okText: "OK",
      onOk() {
        videoRef.current.playsInline = true;
        videoRef.current.pause();
        audioRef.current.pause();
        emitEventToWebview(WebviewEvent.StartAudioEvent, true);
      },
      onCancel() {
        videoRef.current.playsInline = true;
        videoRef.current.pause();
        audioRef.current.pause();
        emitEventToWebview(WebviewEvent.StartAudioEvent, true);
      },
    });

    return () => {
      document.removeEventListener("touchstart", handleTouchStart);
    };
  }, []);

  return (
    <Spin spinning={isLoading} tip={loadingTitle}>
      <audio ref={audioRef} controls hidden />
      <section>
        <div
          className="root-wrapper"
          onClick={() => {
            /* playVideo() */
          }}
          ref={contentRef}
        >
          <Button
            ref={playButtonRef}
            onClick={() => videoRef.current.play()}
            style={{ display: "none" }}
          >
            Play
          </Button>
          <Button
            ref={pauseButtonRef}
            onClick={() => videoRef.current.pause()}
            style={{ display: "none" }}
          >
            Pause
          </Button>

          <Button
            ref={playAudioButtonRef}
            onClick={() => audioRef.current.play()}
            style={{ display: "none" }}
          >
            Play Audio
          </Button>
          {/* background blur */}
          {/* <div
            className="background-blur"
            style={{
              backgroundImage: `url(${imageUrl})`,
            }}
          ></div> */}
          {/* <Header /> */}
          <div
            className="cam-video-container"
            style={{ position: "relative" }}
            onClick={handleShowVideoControl}
          >
            {/* icon tập đúng */}
            {visibleChecked && (
              <div
                style={{
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: 999,
                  width: 120,
                  height: 120,
                  padding: 16,
                  opacity: 0.4,
                  borderRadius: "50%",
                }}
              >
                <img
                  style={{
                    width: "100%",
                  }}
                  src={checkedPng}
                />
              </div>
            )}
            {/* chart số điểm */}
            {typeof score == "number" &&
              !isShowVideoControl &&
              isPlayingVideo &&
              !isHiddenVideo && (
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    position: "absolute",
                    right: 0,
                    left: 0,
                    bottom: 50,
                    width: "100%",
                    zIndex: 999,
                  }}
                >
                  {isPaused.current ? (
                    <img
                      src="/video/ai.gif"
                      alt=""
                      style={{
                        borderRadius: 9999,
                        width: 70,
                        height: 70,
                        objectFit: "cover",
                        marginTop: 20,
                        marginLeft: 20,
                      }}
                    />
                  ) : (
                    <img
                      src="/ai-bot.png"
                      alt=""
                      style={{
                        borderRadius: 9999,
                        width: 70,
                        height: 70,
                        objectFit: "cover",
                        marginTop: 20,
                        marginLeft: 20,
                      }}
                    />
                  )}

                  <div
                    style={{
                      position: "absolute",
                      right: -40,
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                      zIndex: 999,
                    }}
                  >
                    <ScoreChart
                      // score={+(score / 100).toFixed(1)}
                      score={score}
                    />
                  </div>
                </div>
              )}

            <div
              className={
                !isZoomOut
                  ? "canvas-container"
                  : "video-canvas-container-zoomout"
              }
            >
              <div className="text-title">VIDEO MẪU</div>

              <canvas
                className={`${!isZoomOut ? "canvas" : "video-canvas-zoomout"}`}
                id="canvas2"
                style={{
                  transform: "scaleX(-1)",
                  // visibility: isHiddenVideo ? "hidden" : "visible",
                }}
              />

              {((isShowVideoControl && isInstructionDone) ||
                !isPlayingVideo) && (
                <>
                  <div
                    style={{
                      width: "auto",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "e5e4e2",
                      position: "absolute",
                      color: "#fff",
                    }}
                  >
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => playPauseVideo()}
                    >
                      {isPlayingVideo ? (
                        <Pause style={{ color: "#fff", fontSize: 50 }} />
                      ) : (
                        <Play style={{ color: "#fff", fontSize: 50 }} />
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      gap: 12,
                      width: "auto",
                      display: "flex",
                      position: "absolute",
                      color: "#fff",
                      bottom: 30,
                      right: 30,
                    }}
                  >
                    <Speedometer
                      style={{
                        fontSize: 30,
                        width: 30,
                        height: 30,
                        filter: "invert(1)",
                      }}
                      onClick={() => onVideoSpeedometer()}
                    />
                    <Reply10 style={{ fontSize: 30, width: 30, height: 30 }} />
                    <Forward10
                      style={{ fontSize: 30, width: 30, height: 30 }}
                    />
                    <div onClick={toggleMute} style={{ cursor: "pointer" }}>
                      {!isMuted ? (
                        <VolumeUp
                          style={{ fontSize: 30, width: 30, height: 30 }}
                        />
                      ) : (
                        <VolumeOff
                          style={{ fontSize: 30, width: 30, height: 30 }}
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div
              ref={canvasCameraRef}
              className={
                !isZoomOut
                  ? "canvas-container"
                  : "webcam-canvas-container-zoomout"
              }
              onClick={handleZoomOut}
              style={{ borderColor: "#fff" }}
            >
              <div className="text-title">LIVE CAMERA</div>

              {isCorrectPositionUI != null && (
                <div className="body-check">
                  <img
                    alt="body"
                    src={isCorrectPositionUI ? trueBody : falseBody}
                    style={{ height: "100%" }}
                  />
                </div>
              )}

              <canvas
                className={!isZoomOut ? "canvas" : "webcam-canvas-zoomout"}
                id="canvas"
                style={{ display: isHiddenVideo ? "none" : "unset" }}
              />

              <div
                style={{
                  position: "absolute",
                  width: "20px",
                  top: 0,
                  right: 0,
                }}
                onClick={handleZoomOut}
              >
                {isZoomOut ? (
                  <FullscreenOutlined />
                ) : (
                  <FullscreenExitOutlined />
                )}
              </div>
            </div>
            {useMemo(
              () => (
                <>
                  {isShowVideoControl && isInstructionDone && (
                    <div className="slider-wrapper">
                      <Slider
                        style={{ flex: "auto" }}
                        value={videoSeek}
                        dots={false}
                        onChange={handleVideoSeek}
                      />
                    </div>
                  )}
                </>
              ),
              [isShowVideoControl, isInstructionDone, videoSeek]
            )}
          </div>
          <div className="hidden-container">
            {useMemo(
              () => (
                <>
                  {!isHiddenVideo && (
                    <Webcam
                      key={`${videoQueryUrl}-${isHiddenVideo}`} // Buộc render lại Webcam khi key thay đổi
                      ref={webcamRef}
                      audio={false}
                      height={isMobile ? 270 : 480}
                      width={isMobile ? 360 : 640}
                      videoConstraints={VIDEO_CONFIGS}
                      onUserMediaError={onUserMediaError}
                      onUserMedia={onUserMedia}
                      mirrored={isMirror}
                    />
                  )}

                  <ReactHlsPlayer
                    src={videoQueryUrl}
                    controls={true}
                    autoPlay={true}
                    playsInline={true}
                    muted={true}
                    width="640"
                    playerRef={videoRef}
                    // ref={videoRef}
                    onTimeUpdate={onTimeUpdate}
                    onEnded={onEnd}
                    onError={handleVideoError}
                    onLoadedData={handleVideoLoaded}
                    crossOrigin="anonymous"
                    style={{
                      position: "absolute",
                      left: "-9999px",
                      width: 0,
                      height: 0,
                    }}
                    className="hidden-video"
                  />

                  {/* <video
                    style={{
                      position: "absolute",
                      left: "-9999px",
                      width: 0,
                      height: 0,
                    }}
                    className="hidden-video"
                    controls
                    autoPlay={true}
                    playsInline
                    muted
                    width="640"
                    ref={videoRef}
                    onTimeUpdate={onTimeUpdate}
                    onEnded={onEnd}
                    onError={handleVideoError}
                    onLoadedData={handleVideoLoaded}
                    crossOrigin="anonymous"
                  >
                    <source src={videoQueryUrl} type="video/mp4" />
                  </video> */}
                </>
              ),
              [videoQueryUrl, isHiddenVideo, isWebcamActive]
            )}
          </div>
        </div>
      </section>
    </Spin>
  );
}

export default App;
