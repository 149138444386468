import { message } from "antd";
import axios from "axios";

const keyPointApiEndPoint = "https://admin.visedu.vn:10081/v1";
//const keyPointApiEndPoint = "http://localhost:4436/v1"

const request = axios.create({
  baseURL: keyPointApiEndPoint,
  timeout: 30000,
});

export const logApi = {
  createLog: (data) =>
    request({
      url: "/public/logAi",
      method: "post",
      data,
    }),
};

export const keypointApi = {
  find: (fileName) =>
    request({
      url: `/public/bookChapter/keypoint?fileName=${fileName}`,
    }),
  createLog: (data) =>
    request({
      url: "/public/log/yoga/info",
      method: "post",
      data,
    }),
  createLogfile: (log, file) => {
    const formData = new FormData();
    formData.append("log", log);
    formData.append("file", file);
    return request({
      url: "/public/log/yoga/infoFile",
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};
